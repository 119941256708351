import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/web-apps/web-apps/packages/service-ui/src/components/centered-container.js";
import { CloseIcon } from '../components/icons';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CloseIcon mdxType="CloseIcon" />
    <h1>{`Text Extractor`}</h1>
    <p>{`In a workshop with fact checkers, we discovered that the text in images/memes is often the primary focus in debunking efforts. Fact checkers typically type out the text the see in images to search it on Google. We realized that the `}<a parentName="p" {...{
        "href": "https://cloud.google.com/vision/"
      }}>{`Google’s Cloud Vision API`}</a>{` can automate this process. This service is an interface to interact with the API more easily. `}</p>
    <h2>{`Feedback`}</h2>
    <p>{`You can inform about limitations or bugs in this service by emailing us at manage@tattle.co.in. You can also post images where the service fails, on `}<a parentName="p" {...{
        "href": "https://github.com/tattle-made/web-apps/issues/2"
      }}>{`GitHub`}</a>{` . Or simply give us a shout on `}<a parentName="p" {...{
        "href": "https://twitter.com/tattlemade",
        "title": "Twitter"
      }}>{`Twitter`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      